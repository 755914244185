<template>
  <div class="top-banner">
    <div class="bg"></div>
    <div class="ban">
      <div class="title-big">American Delivery Service </div>
      <div class="title-small">
        <div>Experience hassle-free, timely delivery of your products.
        </div>
        <div>We allow you to add at most 200 packages in one order. </div>
      </div>
    </div>
  </div>
  <div class="cont">
    <div class="title">Service Area</div>
    <div class="des">
      As a newborn logistics company, we are thrilled to serve the vibrant and bustling Central Jersey. Our focus is
      solely on this single area, allowing us to dedicate our resources and expertise to provide exceptional delivery
      services tailored specifically to the needs of our local community. Whether you're a business in need of efficient
      supply chain solutions or an individual requiring reliable package delivery, we are here to cater to your needs.
      With our deep understanding of the local landscape, we navigate the streets of Central Jersey with precision and
      efficiency, ensuring your packages and goods reach their destination swiftly and securely. Our commitment to
      excellence, personalized service, and attention to detail sets us apart as a trusted logistics partner in Central
      Jersey. We take pride in supporting the growth and success of our local businesses and serving the residents of
      our
      community with utmost care and dedication.
    </div>
    <div class="des">
      Our warehouse is conveniently situated at the marked location denoted by "x" on the provided map.
    </div>
    <img src="../../../assets/images/home/map.png" alt="">
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku3.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;

  .title {
    font-weight: 300;
    font-size: 40px;
    color: #333;
  }

  .des {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    color: var(--color-text-2);
    padding-top: 16px;
  }

  img {
    padding-top: 28px;
    width: 1200px;
  }
}
</style>
